
















































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { TyreHotelItem } from '../models/TyreHotelItem'
import FetchData from '../components/helpers/FetchData.vue'

@Component({
  components: { FetchData },
})
export default class TyreHotel extends Vue {
  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 25,
    sortBy: ['_id'],
    totalItems: 0,
  }

  totalItems = null
  search = null

  stats = [
    {
      name: 'Summer',
      entries: [
        { name: 'In stock', count: 1479 },
        { name: 'On car', count: 127 },
      ],
    },
    {
      name: 'Winter',
      entries: [
        { name: 'In stock', count: 181 },
        { name: 'On car', count: 1227 },
      ],
    },
  ]

  items = []

  get detailed() {
    return !!this.$route.params.detailed
  }

  set detailed(v) {
    if (v) {
      this.$router.push({
        name: 'TyreHotel',
        params: {
          detailed: 'detailed',
        },
      })
    } else {
      this.$router.push({
        name: 'TyreHotel',
      })
    }
  }

  get endpoint() {
    return `/tyre_hotel?${this.detailed ? 'advanced=1&' : ''}`
  }

  get headers() {
    return [
      { align: 'left', text: 'Tyre hotel id', value: '_id' },
      { text: 'License plate', value: 'car.license_plate' },
      { text: 'Car brand', value: 'car.brand.name' },
      { text: 'Product', value: 'product.name' },
      this.detailed ? { text: 'Drivers name', value: 'driverName' } : null,
      { text: 'Customer name', value: 'car.customer.name' },
      { text: 'Storage location', value: 'storage_location.name' },
      { text: 'Stock position', value: 'stockPosition', sortable: false },
      this.detailed ? { text: 'Tread depth', value: 'storage_lines.mm', sortable: false } : null,
      this.detailed ? { text: 'Status', value: 'status' } : null,
    ].filter((i) => !!i)
  }

  mounted() {}

  loading() {
    this.$vuetify.goTo(0, { easing: 'easeInOutQuad', duration: 150 })
  }

  @Watch('detailed')
  @Watch('pagination', { deep: true })
  fetchItems() {}

  parseItems(item): TyreHotelItem {
    const result = {
      ...item,
      car: {
        _id: null,
        model: '',
        ...(item.car || {}),
        brand: {
          _id: null,
          name: '',
          ...((item.car && item.car.brand) || {}),
        },
      },
      storage_location: {
        name: '',
        ...(item.storage_location || {}),
      },
      product: {
        name: '',
        ...(item.product || {}),
      },
      status: 'active',
      carBrand: item.car ? (item.car.brand ? `${item.car.brand.name} ${item.car.model}` : item.car.model) : '',
      driverName: item.car
        ? item.car.driver
          ? item.car.driver
            ? `${item.car.driver.firstname} ${item.car.driver.lastname}`
            : ''
          : ''
        : '',
      customerName: item.car ? (item.car.customer ? item.car.customer.name : '') : '',
      stockPosition: [
        ...new Set(
          item.storage_lines.map((pos) => {
            return (
              (pos.stock_items[0]
                ? pos.stock_items[0].stock_position
                  ? pos.stock_items[0].stock_position.location
                    ? pos.stock_items[0].stock_position.location.code
                    : ''
                  : ''
                : '') +
              (pos.stock_items[0]
                ? pos.stock_items[0].stock_position
                  ? ` ${pos.stock_items[0].stock_position.name}`
                  : ''
                : '')
            )
          }),
        ),
      ].join(','),
      treadDepth: item.storage_lines.map((pos) => pos.mm).join(','),
    }
    switch (item.status) {
      case 0:
        result.status = 'cancelled'
        break
      case 1:
        result.status = 'active'
        break
    }
    return result as TyreHotelItem
  }
}
